<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    
    <Wrapper class="max-w-2xl">
      <h2 class="mb-5 text-left text-2xl text-blue-dark"><b>¡Información Importante!</b></h2>
      <p class="text-sm text-left">
        Si usted ya presentó una queja ante la entidad vigilada por los mismos hechos y pretensiones consulte el
        estado de su solicitud con el número de radicado.
      </p>
      <div class="w-full mb-5">
        <BaseInput type="text" id="radicado" label="Ingrese el número de radicado de su queja:"
          placeholder="Ej: 225577885" v-model="complaintCode" :danger="feedback" />
        <p v-if="feedback" class="text-left mt-1 text-xs text-red-500">{{ feedback }}</p>
      </div>
      <div class="flex flex-col">
        <a-button type="primary" @click.native="checkComplaint" shape="round" ghost :loading="loadingCheck">
          CONSULTAR ESTADO DE QUEJA
        </a-button>
        <p class="my-5 text-sm text-left">Si la inconformidad corresponde a hechos diferentes, continúe el proceso para
          presentar su queja.</p>
        <a-button @click="$router.push('./registration')" type="primary" shape="round" :block="true">
          PRESENTAR NUEVA QUEJA
        </a-button>
      </div>
    </Wrapper>

    <div class="w-11/12 md:w-full max-w-2xl">
      <AccordionList :items="accordionList" />
    </div>

    <!-- MODAL -->
    <Modal v-if="openModal">
      <div class="w-10/12 m-auto text-sm">
        <p class="mb-5">
          Señor consumidor financiero en el sistema encontrará la queja o reclamo presentado con el número de
          radicado que se muestra a continuación:
        </p>
        <p class="text-gray-500"><b>El código de su queja es:</b></p>
        <h2 class="text-xl md:text-4xl text-blue-dark"><b>{{ complaintCode }}</b></h2>
        <p class="mt-5">
          A continuación podrá visualizar los detalles de seguimiento de la queja interpuesta anteriormente.
        </p>
      </div>
      <div class="w-4/5 m-auto mt-5 flex flex-col gap-4 md:flex-row md:justify-center md:items-center md:gap-4">
        <a-button @click.native="modalFirstAction" type="primary" shape="round" :block="true">
          ENTENDIDO
        </a-button>
        <a-button @click.native="modalSecondAction" type="primary" shape="round" :block="true" ghost>
          VOLVER AL INICIO
        </a-button>
      </div>
    </Modal>

    <!-- MODAL INFORMATION FOR USERS WITHOUT EMAIL -->
    <Modal v-if="openModalInformation">
      <div class="w-10/12 m-auto text-sm">
        <p class="text-gray-500"><b>
            Señor consumidor financiero, recuerde que si desea recibir notificaciones por correo electrónico acerca
            del estado de sus quejas debe actualizar su información personal en la sección de perfil de usuario
            agregando una dirección de correo válida.
          </b></p>
      </div>
      <div class="w-4/5 m-auto mt-5 flex flex-col gap-4 md:flex-row md:justify-center md:items-center md:gap-4">
        <a-button @click.native="hideModalInfo" type="primary" shape="round" :block="true">
          ENTENDIDO
        </a-button>
        <a-button @click.native="modalSecondAction" type="primary" shape="round" :block="true" ghost>
          VOLVER AL INICIO
        </a-button>
      </div>
    </Modal>

  </div>
</template>

<script>

export default {
  data() {
    return {
      accordionList: [
        { title: '¿Ante quién puedo presentar una queja contra una entidad vigilada por la SFC?', body: 'Puede hacerlo directamente ante la entidad vigilada con la cual se tuvo la inconformidad, ante el Defensor del Consumidor Financiero o ante la SFC.' },
        { title: '¿Qué debo tener a mano para presentar una queja ante la SFC?', body: 'Para interponer quejas ante la SFC por inconformidades en contra de las entidades vigiladas con sus productos y servicios, tenga a mano la siguiente información: a) Nombres y apellidos completos de la persona que tiene la inconformidad. b) Nombre de la entidad con la cual tiene la inconformidad. c) Dirección de correo electrónico para recibir la información de su trámite. d) Motivo de la inconformidad. e) Documentos digitalizados que soporten la solicitud.' },
        { title: '¿Cuánto cuesta presentar una queja ante la SFC?', body: 'Presentar una queja ante la SFC o ante la entidad vigilada es un derecho del consumidor financiero que no tiene costo y no necesita intermediarios.' },
        { title: '¿Qué asuntos no puede resolver la SFC al darle trámite a una queja?', body: 'Asuntos que son competencia de los jueces de la República, como: a) Ordenar el pago de indemnizaciones. b) Ordenar la devolución de dineros. c) Intervenir en el desarrollo de procesos judiciales. Si su pretensión tiene relación con el pago de indemnizaciones o devolución de dineros puede presentar una demanda ante la Superintendencia Financiera de Colombia en el siguiente enlace: /inicio/consumidor-financiero/funciones-jurisdiccionales-/abc-para-poner-una-demanda-10102600.' },
        { title: '¿Cuándo la SFC NO podrá tramitar su queja?', body: 'Su inconformidad no tiene relación con los productos o servicios que presta la entidad vigilada. Se trate de algún asunto de tipo civil, laboral o penal.' },
      ],
      feedback: '',
      complaintCode: '',
      openModal: false,
      openModalInformation: this.$store.state.session.user.email == null,
      loadingCheck: false
    }
  },
  methods: {
    async checkComplaint() {
      this.loadingCheck = true;
      if (this.complaintCode == "") {
        this.feedback = 'Debe ingresar un número de radicado  ';
        this.loadingCheck = false;
        return;
      } else {
        this.feedback = "";
      }
      let { data, error } = await this.$api.checkComplaint(this.complaintCode);

      if (error) {
        this.loadingCheck = false;
        this.feedback = 'El número radicado de la interposición no existe en el sistema'
      }
      if (data) {
        this.loadingCheck = false;
        this.openModal = true;
      }
    },
    modalFirstAction() {
      this.$router.push(`tracking/detail/${this.complaintCode}`);
      this.isOpen = false;
    },
    modalSecondAction() {
      this.$router.push("/customer");
      this.isOpen = false;
    },
    hideModalInfo() {
      this.openModalInformation = false;
    }
  }
}
</script>

<style></style>